<template>
  <div class="container trading-fees__container">
    <Tabs col-class="col-6 col-lg-4 col-xl-2 text-center" main-class="tabs flex-wrap justify-content-start justify-content-xl-center">
      <template v-for="(tab, key) in tabs">
        <Tab :name="tab.itemId" :selected="key === 0 ?? true" :key="key">
          <div class="tab__item d-flex flex-column flex-wrap justify-content-center align-items-center">
            <h1 class="title">{{ tab.title }}</h1>
            <p class="desc mb-4">{{ tab.desc }}</p>
            <div class="row mt-75">
              <div class="col-12 col-lg-6">
                <div class="accordion__container w-100">
                  <div class="accordion w-100">
                    <div v-for="(item, key) in tab.items" :key="key" class="accordion-item w-100">
                      <h2 class="accordion-header">
                        <button class="accordion-button" :class="{collapsed: item.id !== 1}" type="button" data-bs-toggle="collapse" :data-bs-target="`#${item.itemId}_${key}`" aria-expanded="true" aria-controls="collapseOne">
                          {{ item.title }}
                        </button>
                      </h2>
                      <div :id="`${item.itemId}_${key}`" class="accordion-collapse collapse" :class="{show: item.id === 1}">
                        <div class="accordion-body">
                          <p>
                            {{ item.text }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-6">
                <p v-for="(item, key) in tab.itemDesc" :key="key">
                  {{ item }}
                </p>
                <LinkBtn class-name="mx-0 mt-4 mt-xl-5"/>
              </div>
            </div>
          </div>
        </Tab>
      </template>
    </Tabs>
  </div>
</template>
<script>
import LinkBtn from '@/components/LinkBtn';
import Tabs from '@/components/Global/Tabs'
import Tab from '@/components/Global/Tab'

export default {
  name: 'StarterAccount',
  components: {
    LinkBtn,
    Tabs,
    Tab
  },
  data () {
    return {
      tabs: [
        {
          title: this.$t('account_types_page.starter_account.tabs.title_0'),
          desc: this.$t('account_types_page.starter_account.tabs.desc_0'),
          itemId: this.$t('account_types_section.items.title_0'),
          itemDesc: [
            this.$t('account_types_page.starter_account.tabs.item_desc_0.0'),
            this.$t('account_types_page.starter_account.tabs.item_desc_0.1')
          ],
          items: [
            {
              id: 1,
              title: this.$t('account_types_page.starter_account.tabs.items_0.title_0'),
              text: this.$t('account_types_page.starter_account.tabs.items_0.text_0')
            },
            {
              id: 2,
              title: this.$t('account_types_page.starter_account.tabs.items_0.title_1'),
              text: this.$t('account_types_page.starter_account.tabs.items_0.text_1')
            },
            {
              id: 3,
              title: this.$t('account_types_page.starter_account.tabs.items_0.title_2'),
              text: this.$t('account_types_page.starter_account.tabs.items_0.text_2')
            },
            {
              id: 4,
              title: this.$t('account_types_page.starter_account.tabs.items_0.title_3'),
              text: this.$t('account_types_page.starter_account.tabs.items_0.text_3')
            },
          ]
        },
        {
          title: this.$t('account_types_page.starter_account.tabs.title_1'),
          desc: this.$t('account_types_page.starter_account.tabs.desc_1'),
          itemId: this.$t('account_types_section.items.title_1'),
          itemDesc: [
            this.$t('account_types_page.starter_account.tabs.item_desc_1.0'),
          ],
          items: [
            {
              id: 1,
              title: this.$t('account_types_page.starter_account.tabs.items_1.title_0'),
              text: this.$t('account_types_page.starter_account.tabs.items_1.text_0')
            },
            {
              id: 2,
              title: this.$t('account_types_page.starter_account.tabs.items_1.title_1'),
              text: this.$t('account_types_page.starter_account.tabs.items_1.text_1')
            },
            {
              id: 3,
              title: this.$t('account_types_page.starter_account.tabs.items_1.title_2'),
              text: this.$t('account_types_page.starter_account.tabs.items_1.text_2')
            },
            {
              id: 4,
              title: this.$t('account_types_page.starter_account.tabs.items_1.title_3'),
              text: this.$t('account_types_page.starter_account.tabs.items_1.text_3')
            },
            {
              id: 5,
              title: this.$t('account_types_page.starter_account.tabs.items_1.title_4'),
              text: this.$t('account_types_page.starter_account.tabs.items_1.text_4')
            },
            {
              id: 6,
              title: this.$t('account_types_page.starter_account.tabs.items_1.title_5'),
              text: this.$t('account_types_page.starter_account.tabs.items_1.text_5')
            },
          ]
        },
        {
          title: this.$t('account_types_page.starter_account.tabs.title_2'),
          desc: this.$t('account_types_page.starter_account.tabs.desc_2'),
          itemId: this.$t('account_types_section.items.title_2'),
          itemDesc: [
            this.$t('account_types_page.starter_account.tabs.item_desc_2.0'),
          ],
          items: [
            {
              id: 1,
              title: this.$t('account_types_page.starter_account.tabs.items_2.title_0'),
              text: this.$t('account_types_page.starter_account.tabs.items_2.text_0')
            },
            {
              id: 2,
              title: this.$t('account_types_page.starter_account.tabs.items_2.title_1'),
              text: this.$t('account_types_page.starter_account.tabs.items_2.text_1')
            },
            {
              id: 3,
              title: this.$t('account_types_page.starter_account.tabs.items_2.title_2'),
              text: this.$t('account_types_page.starter_account.tabs.items_2.text_2')
            },
            {
              id: 4,
              title: this.$t('account_types_page.starter_account.tabs.items_2.title_3'),
              text: this.$t('account_types_page.starter_account.tabs.items_2.text_3')
            },
            {
              id: 5,
              title: this.$t('account_types_page.starter_account.tabs.items_2.title_4'),
              text: this.$t('account_types_page.starter_account.tabs.items_2.text_4')
            },
            {
              id: 6,
              title: this.$t('account_types_page.starter_account.tabs.items_2.title_5'),
              text: this.$t('account_types_page.starter_account.tabs.items_2.text_5')
            },
            {
              id: 7,
              title: this.$t('account_types_page.starter_account.tabs.items_2.title_6'),
              text: this.$t('account_types_page.starter_account.tabs.items_2.text_6')
            },
            {
              id: 8,
              title: this.$t('account_types_page.starter_account.tabs.items_2.title_7'),
              text: this.$t('account_types_page.starter_account.tabs.items_2.text_7')
            },
          ]
        },
        {
          title: this.$t('account_types_page.starter_account.tabs.title_3'),
          desc: this.$t('account_types_page.starter_account.tabs.desc_3'),
          itemId: this.$t('account_types_section.items.title_3'),
          itemDesc: [
            this.$t('account_types_page.starter_account.tabs.item_desc_3.0'),
          ],
          items: [
            {
              id: 1,
              title: this.$t('account_types_page.starter_account.tabs.items_3.title_0'),
              text: this.$t('account_types_page.starter_account.tabs.items_3.text_0')
            },
            {
              id: 2,
              title: this.$t('account_types_page.starter_account.tabs.items_3.title_1'),
              text: this.$t('account_types_page.starter_account.tabs.items_3.text_1')
            },
            {
              id: 3,
              title: this.$t('account_types_page.starter_account.tabs.items_3.title_2'),
              text: this.$t('account_types_page.starter_account.tabs.items_3.text_2')
            },
            {
              id: 4,
              title: this.$t('account_types_page.starter_account.tabs.items_3.title_3'),
              text: this.$t('account_types_page.starter_account.tabs.items_3.text_3')
            },
            {
              id: 5,
              title: this.$t('account_types_page.starter_account.tabs.items_3.title_4'),
              text: this.$t('account_types_page.starter_account.tabs.items_3.text_4')
            },
            {
              id: 6,
              title: this.$t('account_types_page.starter_account.tabs.items_3.title_5'),
              text: this.$t('account_types_page.starter_account.tabs.items_3.text_5')
            },
            {
              id: 7,
              title: this.$t('account_types_page.starter_account.tabs.items_3.title_6'),
              text: this.$t('account_types_page.starter_account.tabs.items_3.text_6')
            }
          ]
        },
        {
          title: this.$t('account_types_page.starter_account.tabs.title_4'),
          desc: this.$t('account_types_page.starter_account.tabs.desc_4'),
          itemId: this.$t('account_types_section.items.title_4'),
          itemDesc: [
            this.$t('account_types_page.starter_account.tabs.item_desc_4.0'),
          ],
          items: [
            {
              id: 1,
              title: this.$t('account_types_page.starter_account.tabs.items_4.title_0'),
              text: this.$t('account_types_page.starter_account.tabs.items_4.text_0')
            },
            {
              id: 2,
              title: this.$t('account_types_page.starter_account.tabs.items_4.title_1'),
              text: this.$t('account_types_page.starter_account.tabs.items_4.text_1')
            },
            {
              id: 3,
              title: this.$t('account_types_page.starter_account.tabs.items_4.title_2'),
              text: this.$t('account_types_page.starter_account.tabs.items_4.text_2')
            },
            {
              id: 4,
              title: this.$t('account_types_page.starter_account.tabs.items_4.title_3'),
              text: this.$t('account_types_page.starter_account.tabs.items_4.text_3')
            },
            {
              id: 5,
              title: this.$t('account_types_page.starter_account.tabs.items_4.title_4'),
              text: this.$t('account_types_page.starter_account.tabs.items_4.text_4')
            },
          ]
        }
      ]
    }
  }
}
</script>
<style lang="scss" scoped>
.gap-t-165 {
  padding-top: 165px;
}
.trading-fees__container {
  padding-top: 140px;
  .title {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    svg {
      position: absolute;
      top: 25px;
      left: 12%;
    }
  }
  .desc {
    margin: 0 auto;
    max-width: 660px;
    width: 100%;
    text-align: center;
  }
}
.accordion__container {
  padding-top: 0;
  .accordion-button {
    font-size: 20px;
    color: #384160;
    padding: 0;
    border: none;
    border-radius: 0!important;
    background-color: transparent;
    box-shadow: none;
  }
  .accordion-item {
    position: relative;
    padding: 3px 45px 3px 45px;
    background-color: transparent;
    border: #959595;
    margin-bottom: 40px;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 8px;
      background-color: #384160;
    }
  }
  .accordion-body {
    padding: 15px 0;
    p {
      font-family: "OpenSans-Light", sans-serif;
      padding-left: 0;
    }
  }
}
.accordion-button::after {
  display: none;
}

.mt-75 {
  margin-top: 75px;
}
</style>
