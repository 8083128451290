<template>
  <div class="container rewards-trading__container">
    <div class="row mt-75">
      <div class="col-12 col-lg-6">
        <div class="accordion__container">
          <div class="accordion" id="accordionSupport">
            <div v-for="(item, key) in accordingItems" :key="key" class="accordion-item">
              <h2 class="accordion-header">
                <button class="accordion-button" :class="{collapsed: item.id !== 1}" type="button" data-bs-toggle="collapse" :data-bs-target="`#collapseOne_${key}`" aria-expanded="true" aria-controls="collapseOne">
                  {{ item.title }}
                </button>
              </h2>
              <div :id="`collapseOne_${key}`" class="accordion-collapse collapse" :class="{show: item.id === 1}" data-bs-parent="#accordionSupport">
                <div class="accordion-body">
                  <p>
                    {{ item.text }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <p>
          {{ $t('fees_page.according.text_0') }}
        </p>
        <p>
          {{ $t('fees_page.according.text_1') }}
        </p>
        <LinkBtn />
      </div>
    </div>
  </div>
</template>

<script>
import LinkBtn from '@/components/LinkBtn';
export default {
  name: 'According',
  components: {
    LinkBtn
  },
  data() {
    return {
      accordingItems: [
        {
          id: 1,
          title: this.$t('fees_page.according.items.title_0'),
          text: this.$t('fees_page.according.items.text_0')
        },
        {
          id: 2,
          title: this.$t('fees_page.according.items.title_1'),
          text: this.$t('fees_page.according.items.text_1')
        },
        {
          id: 3,
          title: this.$t('fees_page.according.items.title_2'),
          text: this.$t('fees_page.according.items.text_2')
        },
        {
          id: 4,
          title: this.$t('fees_page.according.items.title_3'),
          text: this.$t('fees_page.according.items.text_3')
        },
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.rewards-trading__container {
  padding-top: 110px;
  .title {
    margin-bottom: 40px;
  }
  .desc {
    margin: 0 auto;
    max-width: 660px;
    width: 100%;
  }
  .mt-75 {
    margin-top: 75px;
    .button-container {
      margin: 50px 0 0;
    }
  }
  .accordion__container {
    padding-top: 0;
    .accordion-button {
      font-size: 20px;
      color: #384160;
      padding: 0;
      border: none;
      border-radius: 0!important;
      background-color: transparent;
      box-shadow: none;
    }
    .accordion-item {
      position: relative;
      padding: 3px 45px 3px 45px;
      background-color: transparent;
      border: #959595;
      margin-bottom: 40px;
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 8px;
        background-color: #384160;
      }
    }
    .accordion-body {
      padding: 15px 0;
      p {
        font-family: "OpenSans-Light", sans-serif;
      }
    }
  }
  .accordion-button::after {
    display: none;
  }

}
</style>
