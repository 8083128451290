<template>
  <div class="container account-types_section">
    <h4 class="title text-center">
      {{ $t("account_types_section.title") }}
      <svg width="254" height="34" viewBox="0 0 254 34" fill="none" xmlns="http://www.w3.org/2000/svg">
        <!-- SVG content here -->
      </svg>
    </h4>
    <p class="desc text-center">{{ $t("account_types_section.desc") }}</p>
    <div class="four mb100" ref="col1">
      <div class="fourBody">
        <div class="container">
          <div class="fourWrapper">
            <div class="fourRow">
              <div class="fourRowLeft">
                <div class="fourRowLeftImg">
                  <img src="@/assets/img/index/slider/notebook.png" alt="NoteBook" />
                </div>
                <div class="fourRowLeftSlider">
                  <swiper
                      ref="mySwiper"
                      :options="swiperOptions"
                  >
                    <swiper-slide v-for="(item) in array" :key="item.id">
                      <div class="slide">
                        <h3 class="slideTitle">
                          <span v-for="subS in item.title.split('\n')" :key="subS">{{ subS }}</span>
                        </h3>
                        <p class="slideSubTitle font-16">{{ item.text }}</p>
                      </div>
                    </swiper-slide>
                    <div class="swiper-pagination swiper-pagination-bullets types__wrap d-flex justify-content-between" slot="pagination"></div>
                  </swiper>
                </div>
              </div>
              <div class="fourRowRight">
                <div class="fourRowRightNext" @click="handleNextClick">
                  <div class="fourRowRightNextImg">
                    <img src="@/assets/img/index/new/4/3.png" alt="" :style="{ transform: `rotate(${rotateImg}deg)` }" />
                    <p class="fourRowRightNextImgAbs">{{ activeI }}</p>
                  </div>
                  <div class="fourRowRightNextLine"></div>
                  <p class="fourRowRightNextMax mb-1">{{ array.length }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'

// Import Swiper styles
import 'swiper/css/swiper.css'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export default {
  name: 'AccountTypes',
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      activeI: 1,
      swiperOptions: {
        loop: true,
        navigation: {
          nextEl: '.fourRowRightNext',
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          renderBullet: (index, className) => {
            return this.renderBullet(index, className);
          }
        },
        on: {
          slideChange: () => {
            this.handleSlideChange();
          },
        }
      },
      width: 0,
      rotateImg: 0,
      array: [
        {
          id: 0,
          type: this.$t('account_types_section.items.title_0'),
          title: this.$t('account_types_section.items.subtitle_0'),
          text: this.$t('account_types_section.items.text_0')
        },
        {
          id: 1,
          type: this.$t('account_types_section.items.title_1'),
          title: this.$t('account_types_section.items.subtitle_1'),
          text: this.$t('account_types_section.items.text_1')
        },
        {
          id: 2,
          type: this.$t('account_types_section.items.title_2'),
          title: this.$t('account_types_section.items.subtitle_2'),
          text: this.$t('account_types_section.items.text_2')
        },
        {
          id: 3,
          type: this.$t('account_types_section.items.title_3'),
          title: this.$t('account_types_section.items.subtitle_3'),
          text: this.$t('account_types_section.items.text_3')
        },
        {
          id: 4,
          type: this.$t('account_types_section.items.title_4'),
          title: this.$t('account_types_section.items.subtitle_4'),
          text: this.$t('account_types_section.items.text_4')
        }
      ]
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper
    }
  },
  mounted() {
    this.updateWidth();
    window.addEventListener('resize', this.updateWidth);
  },
  destroyed() {
    window.removeEventListener('resize', this.updateWidth);
  },
  methods: {
    handleSlideChange() {
      if (this.$refs.mySwiper.$swiper) {
        this.activeI = ++this.$refs.mySwiper.$swiper.realIndex;
      }
    },
    renderBullet(index, className) {
      const item = this.array[index];
      return `<span class="${className} types__item">${item.type}</span>`;
    },
    handleNextClick() {
      this.rotateImg += 60;
    },
    updateWidth() {
      this.width = window.innerWidth;
      if (this.width > 1220) {
        gsap.timeline().to(this.$refs.monetRef1, {
          scrollTrigger: {
            trigger: this.$refs.col1,
            start: 'top bottom',
            end: 'top top',
            scrub: 2
          },
          y: 0
        });
      }
    }
  }
};
</script>


<style lang="scss" scoped>
.account-types_section {
  padding-top: 170px;
  .title {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    svg {
      position: absolute;
      top: 40px;
      left: 20%;
    }
  }
  .desc {
    margin: 0 auto;
    max-width: 660px;
    padding: 0 120px;
    width: 100%;
  }
}
.fourBody {
  position: relative;
  margin-top: 75px;
}
.fourTitle {
  max-width: 500px;
  margin-bottom: 30px;
}
.fourSubtitle {
  margin-bottom: 60px;
  max-width: 500px;
}
.fourWrapper {
}
.fourRow {
  display: flex;
  justify-content: space-between;
  @media (max-width: $md4+px) {
  }
}
.fourRowLeft {
  flex: 0 0 900px;
  max-width: 900px;
  @media (max-width: $md2+px) {
    flex: 0 0 800px;
    max-width: 800px;
  }
  position: relative;
  padding: 60px 0 240px 0;
  @media (max-width: $md3+px) {
    padding: 60px 0 318px 0;
  }
  @media (max-width: 550px) {
    padding: 30px 0 270px 0;
  }
  @media (max-width: $md4+px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
  &:after {
    content: "";
    display: inline-block;
    right: 0;
    top: 0;
    position: absolute;
    width: 200%;
    height: 100%;
    background: #38416066;
    opacity: 0.4;
    @media (max-width: $md4+px) {
      right: -100px;
    }
  }
}
.fourRowLeftImg {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 318px;
  height: 271px;
  pointer-events: none;
  z-index: 2;
  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
    object-position: right top;
  }
  @media (max-width: $md3+px) {
    right: unset;
    width: 216px;
    height: 248px;
    left: -40px;
    top: unset;
    bottom: 30px;
    img {
      object-position: left bottom;
    }
  }
}
.fourRowLeftSlider {
  max-width: 500px;
  @media (max-width: $md2+px) {
    max-width: 450px;
  }
}
.slide {
  max-width: 500px;
  @media (max-width: $md2+px) {
    max-width: 450px;
  }
}
.slideTitle {
  font-family: "OpenSans-Bold", sans-serif;
  color: #384160;
  margin-bottom: 30px;
  font-size: 30px;
  span {
    &:first-child {
      display: block;
    }
  }
}
.slideSubTitle {
  font-weight: 300;
}
.fourRowRight {
  flex: 0 1 100%;

  position: relative;
}
.fourRowRightAbout {
  position: absolute;
  font-weight: 800;
  font-size: 174px;
  line-height: 1.5;
  text-transform: uppercase;

  color: #ffffff;
  right: -170px;
  bottom: 140px;
  @media (max-width: 1275px) {
    font-size: 144px;
    right: -130px;
  }
  @media (max-width: $md3+px) {
    display: none;
  }

  mix-blend-mode: normal;
  opacity: 0.4;
  transform: rotate(-90deg) translate(300px, 0);
}
.fourRowRightNext {
  position: absolute;
  height: 190px;
  left: -75px;
  top: 160px;
  cursor: pointer;
  align-items: center;
  display: flex;
  @media (max-width: $md3+px) {
    top: unset;
    bottom: 30px;
    left: -230px;
  }
  @media (max-width: $md4+px) {
    left: -300px;
  }
  @media (max-width: 550px) {
    bottom: 125px;
    left: -190px;
  }
}
.fourRowRightNextImg {
  position: relative;
  width: 189.9px;
  height: 190px;
  @media (max-width: 1275px) {
    width: 160px;
    height: 160px;
  }
  @media (max-width: 550px) {
    width: 89.8px;
    height: 89.84px;
  }

  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
    transition: all 0.3s linear;
  }
  transition: all 0.3s linear;
}
.fourRowRightNextImgAbs {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  font-weight: 400;
  font-size: 20px;
  color: #242424;
  @media (max-width: 550px) {
    font-size: 12px;
  }
}
.fourRowRightNextLine {
  width: 100px;
  height: 2px;
  margin: 0 13px;
  background-color: #242424;
}
.fourRowRightNextMax {
  font-size: 20px;
  color: #242424;
  @media (max-width: 550px) {
    font-size: 12px;
  }
}

</style>
