<template>
  <section>
    <Menu />
    <main class="overflow-hidden">
      <Banner />
      <According />
      <TradingFees />
      <FeePolicyAccountTypes />
      <NexusPro />
    </main>

    <Footer />
  </section>
</template>

<script>
import Menu from '@/components/Menu';
import Banner from '@/components/Fees/Banner';
import According from '@/components/Fees/According';
import TradingFees from '@/components/Fees/TradingFees';
import FeePolicyAccountTypes from '@/components/Fees/FeePolicyAccountTypes';
import NexusPro from '@/components/index/NexusPro';

import Footer from '@/components/Footer';

export default {
  name: 'HomeView',
  components: {
    Menu,
    Banner,
    According,
    TradingFees,
    FeePolicyAccountTypes,
    NexusPro,
    Footer
  },
  layout: 'basic',

  metaInfo () {
    return { title: this.$t('meta.title_fees_page'), meta: [{
        vmid: 'description',
        name: 'description',
        content: this.$t('meta.desc_fees_page')
      }],
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
