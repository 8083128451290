<template>
  <section>
    <Menu />
    <main>
      <Banner />
      <TradingInstruments :show-title="false" class-name="tools-instruments_section" :text-block="computedText" :image-block="image" />
      <PopularAssets />
      <RewardsTrading />
      <ExploreTradingTools />
      <NexusPro />
    </main>
    <Footer />
  </section>
</template>

<script>
import Menu from '@/components/Menu';
import Banner from '@/components/Tools/Banner';
import TradingInstruments from '@/components/index/TradingInstruments';
import PopularAssets from '@/components/Tools/PopularAssets';
import RewardsTrading from '@/components/Tools/RewardsTrading';
import ExploreTradingTools from '@/components/Tools/ExploreTradingTools';
import NexusPro from '@/components/index/NexusPro';
import Footer from '@/components/Footer';

export default {
  name: 'HomeView',
  components: {
    Menu,
    Banner,
    TradingInstruments,
    PopularAssets,
    RewardsTrading,
    ExploreTradingTools,
    NexusPro,
    Footer
  },
  data() {
    return {
      image: require('@/assets/img/tools/trade-img.png'),
    }
  },
  layout: 'basic',
  computed: {
    computedText() {
      return this.$t('trading_tools_page.trading_instruments.title')
    }
  },

  metaInfo () {
    return { title: this.$t('meta.title_trading_page'), meta: [{
        vmid: 'description',
        name: 'description',
        content: this.$t('meta.desc_trading_page')
      }],
    }
  }
}
</script>

<style lang="scss" scoped>
.tools-instruments_section {
  padding-top: 70px;
}
</style>
