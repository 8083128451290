<template>
  <div class="popular-assets__container container">
    <h4 class="title text-center">
      {{ $t('trading_tools_page.popular_assets.title') }}
    </h4>
    <p class="desc text-center">{{ $t('trading_tools_page.popular_assets.desc') }}</p>
    <div class="popular-assets__wrap position-relative">
      <div class="d-flex flex-wrap flex-column">
        <div class="col-12 col-xl-6">
          <Swiper :options="swiperOptions" ref="mySwiper" class="swiper-container">
            <div class="swiper-wrapper">
              <div
                  v-for="item in navItems"
                  :key="item.id"
                  :class="[item.class, { 'swiper-slide-active2': item.active }]"
              >
                <span @click.prevent="setActiveTab(item.id)">{{ item.name }}</span>
              </div>
            </div>
          </Swiper>
        </div>
        <!-- wrapping div to extend page below last section -->
        <div class="col-12 col-xl-6" style="height: 200px;overflow: hidden;">
          <!-- Page sections -->
          <section
              v-for="section in sections"
              :key="section.id"
              :class="section.class"
              :id="section.id"
              v-show="section.id === activeTab"
          >
            <p>{{ section.desc }}</p>
          </section>
        </div>
        <div class="col-12 col-xl-6">
          <img src="@/assets/img/tools/mob.png" alt="" class="img-fluid img-el">
        </div>
      </div>
    </div>
    <div class="row gap-t-165">
      <div class="col-12 col-md-7 d-none d-xl-block">
        <div class="d-flex justify-content-center align-items-baseline w-100">
          <img src="@/assets/img/tools/woman-img.png" alt="" class="img-fluid">
        </div>
      </div>
      <div class="col-12 col-xl-5">
        <div class="d-flex flex-column justify-content-center h-100">
          <p id="pop-el-1">
            {{ $t('trading_tools_page.popular_assets.text_0') }}
          </p>
          <p id="pop-el-2">
            {{ $t('trading_tools_page.popular_assets.text_1') }}
          </p>
          <LinkBtn class-name="mx-0 mt-4" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';
import LinkBtn from '@/components/LinkBtn.vue';
import { gsap } from 'gsap';

export default {
  components: {
    LinkBtn,
    Swiper,
  },
  data() {
    return {
      activeTab: null,
      swiperOptions: {
        slidesPerView: 'auto',
        freeMode: {
          enabled: true,
        },
        spaceBetween: 10,
        mousewheel: true,
      },
      sections: [
        { id: 'appleID', class: 'appleClass', name: this.$t('trading_tools_page.popular_assets.items.title_0'), desc: this.$t('trading_tools_page.popular_assets.items.text_0') },
        { id: 'bitcoinID', class: 'bitcoinClass', name: this.$t('trading_tools_page.popular_assets.items.title_1'), desc: this.$t('trading_tools_page.popular_assets.items.text_1') },
        { id: 'goldID', class: 'goldClass', name: this.$t('trading_tools_page.popular_assets.items.title_2'), desc: this.$t('trading_tools_page.popular_assets.items.text_2') },
        { id: 'forexPairID', class: 'forexPairClass', name: this.$t('trading_tools_page.popular_assets.items.title_3'), desc: this.$t('trading_tools_page.popular_assets.items.text_3') },
        { id: 'indexID', class: 'indexClass', name: this.$t('trading_tools_page.popular_assets.items.title_4'), desc: this.$t('trading_tools_page.popular_assets.items.text_4') },
      ],
      navItems: [
        { id: 'appleID', class: 'appleClass', name: this.$t('trading_tools_page.popular_assets.items.title_0'), active: false },
        { id: 'bitcoinID', class: 'bitcoinClass', name:  this.$t('trading_tools_page.popular_assets.items.title_1'), active: false },
        { id: 'goldID', class: 'goldClass', name: this.$t('trading_tools_page.popular_assets.items.title_2'), active: false },
        { id: 'forexPairID', class: 'forexPairClass', name: this.$t('trading_tools_page.popular_assets.items.title_3'), active: false },
        { id: 'indexID', class: 'indexClass', name: this.$t('trading_tools_page.popular_assets.items.title_4'), active: false },
      ],
    };
  },
  methods: {
    setActiveTab(id) {
      this.activeTab = id;
    },
  },
  mounted() {
    if (window.innerWidth >= 1200) {
      const elements = [
        { id: '#pop-el-1', x: 200 },
        { id: '#pop-el-2', x: 200 },
      ];
      elements.forEach(element => {
        gsap.fromTo(
            document.querySelector(element.id),
            {
              opacity: 0,
              x: element.x,
            },
            {
              opacity: 1,
              x: 0,
              duration: 3,
              scrollTrigger: {
                trigger: document.querySelector(element.id),
                start: "top bottom",
              }
            }
        );
      });
    }
    if (this.navItems.length > 0) {
      this.activeTab = this.navItems[0].id;
    }
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.7,
    };

    const observerCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const navItem = this.navItems.find(item => item.id === entry.target.id);
          const navItemAlt = navItem.alt; // Используем значение alt из navItems
          this.$refs.mySwiper.$swiper.slideTo(navItemAlt, 300, true);
          this.$set(navItem, 'active', true); // Reactively set active state
          this.navItems.forEach((item) => {
            if (item !== navItem) {
              this.$set(item, 'active', false); // Reactively remove active state
            }
          });
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);

    this.sections.forEach((sec) => {
      const sectionElement = document.getElementById(sec.id);
      observer.observe(sectionElement);
    });
  },
};
</script>

<style lang="scss" scoped>
.popular-assets__container {
  padding-top: 170px;
  .gap-t-165 {
    padding-top: 90px;
  }
  .desc {
    margin: 0 auto 50px;
    max-width: 660px;
    width: 100%;
  }
  .popular-assets__wrap {
    height: 303px;
    background-color: rgba(56, 65, 96, 0.4);
    margin-top: 190px;
    padding: 50px;
  }
  .img-el {
    position: absolute;
    bottom: 0;
    right: 15%;
  }
}
.swiper-container {
  width: 100%;
}

.swiper-wrapper {
  margin-bottom: 80px;
}



.swiper-container {
  .swiper-wrapper {
    display: flex;
    justify-content: space-between;
    &:after {
      content: '';
      height: 8px;
      width: 100%;
      position: absolute;
      bottom: -33px;
      display: flex;
      background-color: rgba(255, 255, 255, 0.2);
      border-radius: 2px;

    }
  }
  span {
    font-size: 16px;
    font-weight: 600;
    color: rgba(56, 65, 96, .5);
    text-align: center;
    text-decoration: none;
    cursor: pointer;
  }

  .swiper-slide-active2 span {
    color: #384160;
    border-radius: 25px;
    position: relative;
    &:after {
      content: '';
      height: 8px;
      width: 100%;
      position: absolute;
      bottom: -35px;
      display: flex;
      background-color: #384160;
      border-radius: 2px;
    }
  }
}

section {
  height: 200px;
}
</style>
