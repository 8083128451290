<template>
  <section>
    <Menu />
    <main class="overflow-hidden position-relative">
      <Banner />
      <Content />
      <NexusPro />
    </main>

    <Footer />
  </section>
</template>

<script>
import Menu from '@/components/Menu';
import Banner from '@/components/AboutUs/Banner';
import Content from '@/components/AboutUs/Content';
import NexusPro from '@/components/index/NexusPro';

import Footer from '@/components/Footer';

export default {
  name: 'AboutUsView',
  components: {
    Menu,
    Banner,
    Content,
    NexusPro,
    Footer
  },
  layout: 'basic',

  metaInfo () {
    return { title: this.$t('meta.title_about_page'), meta: [{
        vmid: 'description',
        name: 'description',
        content: this.$t('meta.desc_about_page')
      }],
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
