<template>
  <div class="button-container" :class="className">
    <span class="mas">{{ computedText }}</span>
    <a :href="computedLink">{{ computedText }}</a>
  </div>
</template>

<script>
export default {
  props: {
    buttonText: {
      type: String,
      default: null
    },
    className: {
      type: String
    },
    link: {
      type: String,
      default: null
    },
  },
  computed: {
    computedLink() {
      return this.link || this.$clientAreaSignIn;
    },
    computedText() {
      return this.buttonText || this.$t('start_trading');
    }
  }
};
</script>

<style lang="scss">
.mas {
  position: absolute;
  color: #384160;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  width: 101%;
  font-family: 'OpenSans-Bold', sans-serif;
  font-size: 17px;
  overflow: hidden;
}

@-webkit-keyframes ani {
  from {
    -webkit-mask-position: 0 0;
    mask-position: 0 0;
  }

  to {
    -webkit-mask-position: 100% 0;
    mask-position: 100% 0;
  }
}

@keyframes ani {
  from {
    -webkit-mask-position: 0 0;
    mask-position: 0 0;
  }

  to {
    -webkit-mask-position: 100% 0;
    mask-position: 100% 0;
  }
}

@-webkit-keyframes ani2 {
  from {
    -webkit-mask-position: 100% 0;
    mask-position: 100% 0;
  }

  to {
    -webkit-mask-position: 0 0;
    mask-position: 0 0;
  }
}

@keyframes ani2 {
  from {
    -webkit-mask-position: 100% 0;
    mask-position: 100% 0;
  }

  to {
    -webkit-mask-position: 0 0;
    mask-position: 0 0;
  }
}

.button-container {
  position: relative;
  width: 185px;
  height: 56px;
  margin: 0 auto;
  overflow: hidden;
  border: 1px solid #384160;
  font-family: 'OpenSans-Bold', sans-serif;
  transition: 0.5s;
  border-radius: 30px;

  a {
    font-family: 'OpenSans-Bold', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    text-align: center;
    width: 101%;
    height: 100%;
    font-size: 17px;
    background: #384160;
    -webkit-mask: url('@/assets/img/natureSmaller.png');
    mask: url('@/assets/img/natureSmaller.png');
    -webkit-mask-size: 7100% 100%;
    mask-size: 7100% 100%;
    border: none;
    color: #fff;
    cursor: pointer;
    -webkit-animation: ani2 0.7s steps(70) forwards;
    animation: ani2 0.7s steps(70) forwards;

    &:hover {
      -webkit-animation: ani 0.7s steps(70) forwards;
      animation: ani 0.7s steps(70) forwards;
    }
  }
}
</style>
