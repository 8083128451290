<template>
  <footer class="footer">
    <div class="row">
      <div class="col-12 col-lg-6">
        <div class="row">
          <div class="col-12 col-lg-4 mb-4">
            <img src="@/assets/img/logo-white.png" alt="Logo" class="img-fluid">
          </div>
          <div class="col-6 col-lg-4">
            <p>{{ $t('footer_section.contacts_label') }}: <br /> <a class="mt-1" href="tel:+441863440363">+441863440363</a></p>
            <p>{{ $t('footer_section.address_label') }}: <span class="d-flex mt-1">Edmund House 12-22, Newhall Street, Birmingham, B3 3ASB 3 3 A S, UK</span></p>
            <p>{{ $t('footer_section.email_label') }}: <br /> <a class="mt-1" href="mailto:support@dynasty-trade.io">support@dynasty-trade.io</a></p>
          </div>
          <div class="col-6 col-lg-4">
            <div class="d-flex flex-column align-items-baseline">
              <a href="/docs/terms_and_conditions.pdf" target="_blank">{{ $t('footer_section.terms_of_use') }}</a>
              <a href="/docs/privacy_policy.pdf" target="_blank">{{ $t('footer_section.privacy_policy') }}</a>
              <a href="/docs/amc_policy.pdf" target="_blank">{{ $t('footer_section.aml_policy') }}</a>
              <a href="/docs/DYNASTY_TRADES_LTD_FCA.pdf" target="_blank">FCA</a>
              <a href="/docs/DYNASTY_TRADES_LTD_(CySec).pdf" target="_blank">CySEC</a>
              <a href="/docs/License_DynastyTrade.pdf" target="_blank">IFSCA</a>
              <a href="https://register.fca.org.uk/s/firm?id=0014G00002anH2EQAU" target="_blank">Regulation</a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6 rules" v-html="$t('footer_section.disclaimer_label')"></div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style lang="scss">
.footer {
  z-index: 9;
  background-color: #384160;
  padding: 70px 150px;
  margin-top: 170px;
  .img-fluid {
    max-width: 100px;
    width: 100%;
  }
  a {
    text-decoration: none;
    margin-bottom: 16px;
    position: relative;
    &:hover {
      &:before, &:after {
        opacity: 1;
        transform: translateY(0px);
      }
    }
    &:before, &:after {
      position: absolute;
      left: 0px;
      width: 100%;
      height: 2px;
      background: rgba(255, 255, 255, .7);
      content: "";
      opacity: 0;
      transition: all 0.3s;
    }
    &:before {
      top: 0;
      transform: translateY(10px);
    }
    &:after {
      bottom: 0;
      transform: translateY(-10px);
    }
  }
  p, a {
    color: rgba(255, 255, 255, .7);
  }
  .rules {
    p {
      font-size: 12px;
      &.copyright {
        font-size: 14px;
        margin-top: 32px;
      }
    }
  }
}
</style>
