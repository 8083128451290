<template>
  <div class="slider__container">
    <div class="container">
      <div class="d-flex justify-content-end navigation__wrap">
        <div class="rightPrev arrow">
          <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="39.5" y="39.5" width="39" height="39" rx="19.5" transform="rotate(-180 39.5 39.5)" stroke="black"/>
            <path d="M23 12L15 19.7273L23 27" stroke="black"/>
          </svg>
        </div>
        <div class="rightNext arrow">
          <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" stroke="black"/>
            <path d="M17 28L25 20.2727L17 13" stroke="black"/>
          </svg>
        </div>
      </div>
    </div>
    <swiper
        class="swiper"
        :options="swiperOptions"
    >
      <swiper-slide v-for="(item, key) in sliderItems" :key="key" class="slide d-flex flex-wrap">
        <div class="col-4 d-none d-md-flex">
          <img :src="item.img" class="img-fluid" :alt="item.title">
        </div>
        <div class="col-12 col-md-8">
          <h5 class="subtitle">{{ item.title }}</h5>
          <p>{{ item.text }}</p>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'

// Import Swiper styles
import 'swiper/css/swiper.css'
export default {
  name: 'Slider',
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      activeI: 1,
      swiperOptions: {
        slidesPerView: 2,
        spaceBetween: 100,
        centeredSlides: true,
        navigation: {
          nextEl: '.rightNext',
          prevEl: '.rightPrev'
        },
        breakpoints: {
          '320': {
            slidesPerView: 1,
            spaceBetween: 100,
            autoHeight: true
          },
          '1200': {
            slidesPerView: 2,
            spaceBetween: 100,
          },
        }
      },
      sliderItems: [
        {
          title: this.$t('support_page.slider.title_0'),
          text: this.$t('support_page.slider.text_0'),
          img: require('@/assets/img/support/slider-img-1.png'),
        },
        {
          title: this.$t('support_page.slider.title_1'),
          text: this.$t('support_page.slider.text_1'),
          img: require('@/assets/img/support/slider-img-2.png'),
        },
        {
          title: this.$t('support_page.slider.title_2'),
          text: this.$t('support_page.slider.text_2'),
          img: require('@/assets/img/support/slider-img-3.png'),
        },
      ]
    }
  }
}
</script>
<style lang="scss" scoped>
.arrow {
  outline: none;
  &.rightPrev {
    margin-right: 20px;
  }
  &.swiper-button-disabled {
    opacity: .3;
    cursor: not-allowed;
  }
}
.slide {
  min-height: 500px;
  height: 100%;
  .col-4 {
    img {
      width: 100%;
      object-fit: cover;
    }
  }
  .col-12 {
    padding: 50px 35px;
    background-color: rgba(56, 65, 96, .3);
    .subtitle {
      font-size: 23px;
    }
    p {
      font-family: "OpenSans-Light", sans-serif;
    }
  }
}
</style>
