<template>
  <div class="container content__wrap rewards-trading__container">
    <div class="d-flex justify-content-start justify-content-xl-center">
      <h4 class="title">{{ $t('about_page.content.title_0') }}</h4>
    </div>
    <div class="row justify-content-xl-between">
      <div class="col-12 col-xl-5">
        <p>{{ $t('about_page.content.text_0') }}</p>
        <p>{{ $t('about_page.content.text_1') }}</p>
        <p>{{ $t('about_page.content.text_2') }}</p>
      </div>
      <div class="col-12 col-xl-5">
        <p>{{ $t('about_page.content.text_3') }}</p>
        <p>{{ $t('about_page.content.text_4') }}</p>
        <p>{{ $t('about_page.content.text_5') }}</p>
      </div>
      <div class="d-flex justify-content-start justify-content-xl-center gap-t-170">
        <h4 class="title">{{ $t('about_page.content.title_1') }}</h4>
      </div>
      <div class="col-12 col-xl-5">
        <p>{{ $t('about_page.content.text_6') }}</p>
        <p>{{ $t('about_page.content.text_7') }}</p>
        <p>{{ $t('about_page.content.text_8') }}</p>
      </div>
      <div class="col-12 col-xl-5">
        <p>{{ $t('about_page.content.text_9') }}</p>
        <p>{{ $t('about_page.content.text_10') }}</p>
        <p>{{ $t('about_page.content.text_11') }}</p>
      </div>
      <div class="d-flex justify-content-start justify-content-xl-center gap-t-170">
        <h4 class="title">{{ $t('about_page.content.title_2') }}</h4>
      </div>
      <div class="col-12 col-xl-5">
        <p>{{ $t('about_page.content.text_12') }}</p>
        <p>{{ $t('about_page.content.text_13') }}</p>
        <p>{{ $t('about_page.content.text_14') }}</p>
      </div>
      <div class="col-12 col-xl-5">
        <p>{{ $t('about_page.content.text_15') }}</p>
        <p>{{ $t('about_page.content.text_16') }}</p>
        <p>{{ $t('about_page.content.text_17') }}</p>
      </div>
      <div class="d-flex justify-content-start justify-content-xl-center gap-t-170">
        <h4 class="title">Our goals</h4>
      </div>
      <div class="col-12 col-xl-5">
        <div class="accordion__container">
          <div class="accordion" id="accordionSupport">
            <div v-for="(item, key) in accordingItems" :key="key" class="accordion-item">
              <h2 class="accordion-header">
                <button class="accordion-button" :class="{collapsed: item.id !== 1}" type="button" data-bs-toggle="collapse" :data-bs-target="`#collapseOne_${key}`" aria-expanded="true" aria-controls="collapseOne">
                  {{ item.title }}
                </button>
              </h2>
              <div :id="`collapseOne_${key}`" class="accordion-collapse collapse" :class="{show: item.id === 1}" data-bs-parent="#accordionSupport">
                <div class="accordion-body">
                  <p>
                    {{ item.text }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-xl-5">
        <p>{{ $t('about_page.content.text_18') }}</p>
        <p>{{ $t('about_page.content.text_19') }}</p>
        <LinkBtn class-name="mx-0 mt-4 mt-xl-5" />
      </div>
      <div class="d-flex justify-content-start justify-content-xl-center gap-t-170">
        <h4 class="title">{{ $t('about_page.content.title_3') }}</h4>
      </div>
      <div class="col-12 col-xl-5">
        <p>{{ $t('about_page.content.text_20') }}</p>
        <p>{{ $t('about_page.content.text_21') }}</p>
      </div>
      <div class="col-12 col-xl-5">
        <p>{{ $t('about_page.content.text_22') }}</p>
        <p>{{ $t('about_page.content.text_23') }}</p>
        <LinkBtn class-name="mx-0 mt-4 mt-xl-5" />
      </div>
    </div>
  </div>
</template>
<script>
import LinkBtn from '@/components/LinkBtn';
export default {
  name: 'Content',
  components: {
    LinkBtn
  },
  data() {
    return {
      accordingItems: [
        {
          id: 1,
          title: this.$t('about_page.content.items.title_0'),
          text: this.$t('about_page.content.items.text_0')
        },
        {
          id: 2,
          title: this.$t('about_page.content.items.title_1'),
          text: this.$t('about_page.content.items.text_1')
        },
        {
          id: 3,
          title: this.$t('about_page.content.items.title_2'),
          text: this.$t('about_page.content.items.text_2')
        },
        {
          id: 4,
          title: this.$t('about_page.content.items.title_3'),
          text: this.$t('about_page.content.items.text_3')
        },
      ]
    }
  }
}
</script>
<style lang="scss" scoped>
.gap-t-170 {
  padding-top: 170px;
}
.content__wrap {
  padding-top: 90px;
  .title {
    padding-bottom: 75px;
  }
  .col-xl-5 {
    p {
      font-family: "OpenSans-Light", sans-serif;
    }
  }
  .accordion__container {
    padding-top: 0;
    .accordion-button {
      font-size: 20px;
      color: #384160;
      padding: 0;
      border: none;
      border-radius: 0!important;
      background-color: transparent;
      box-shadow: none;
    }
    .accordion-item {
      position: relative;
      padding: 3px 45px 3px 45px;
      background-color: transparent;
      border: #959595;
      margin-bottom: 40px;
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 8px;
        background-color: #384160;
      }
    }
    .accordion-body {
      padding: 15px 0;
      p {
        font-family: "OpenSans-Light", sans-serif;
      }
    }
  }
  .accordion-button::after {
    display: none;
  }
}
</style>
