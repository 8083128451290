<template>
  <header class="header__banner d-flex align-items-center">
    <ul class="navbar__banner d-none d-xl-flex justify-content-between w-100 align-items-center">
      <li class="nav__item">
        <router-link :to="{ name: 'home' }"
        ><img src="@/assets/img/logo.png" alt="" class="img-fluid"
        /></router-link>
      </li>
      <li class="nav__item">
        <router-link
          :to="{ name: 'tools' }"
          class="nav__link"
          :class="{ active: currentRoute === 'tools' }"
        >
          {{ $t('menu_item_1') }}
        </router-link>
      </li>
      <li class="nav__item">
        <router-link
          :to="{ name: 'fees' }"
          class="nav__link"
          :class="{ active: currentRoute === 'fees' }"
        >
          {{ $t('menu_item_2') }}
        </router-link>
      </li>
      <li class="nav__item">
        <router-link
          :to="{ name: 'account-types' }"
          class="nav__link"
          :class="{ active: currentRoute === 'account-types' }"
        >
          {{ $t('menu_item_3') }}
        </router-link>
      </li>
      <li class="nav__item">
        <router-link
          :to="{ name: 'support' }"
          class="nav__link"
          :class="{ active: currentRoute === 'support' }"
        >
          {{ $t('menu_item_4') }}
        </router-link>
      </li>
      <li class="nav__item">
        <router-link
          :to="{ name: 'about-us' }"
          class="nav__link"
          :class="{ active: currentRoute === 'about-us' }"
        >
          {{ $t('menu_item_5') }}
        </router-link>
      </li>
      <li class="nav__item d-flex">
        <button
            class="dropdown-toggle text-uppercase"
            type="button"
            id="dropdownLang"
            data-bs-toggle="dropdown"
            aria-expanded="false"
        >
          {{ $i18n.locale }}
        </button>
        <LocaleDropdown />
      </li>
      <li class="nav__item d-flex">
        <LinkBtn :button-text="$t('login')" class-name="header__link" />
      </li>
    </ul>
    <div id="menu-mobile" class="d-flex d-xl-none">
      <div id="bg-menu-mobile"></div>
      <ul :class="isOpen ? 'd-block' : 'd-none'">
        <li v-for="(item, index) in menuItems" :key="index">
          <router-link :to="{ name: item.route }">{{ item.name }}</router-link>
        </li>
        <li class="d-flex justify-content-center align-items-center">
          <LinkBtn button-text="Login" class-name="mobile-login__btn ms-2" />
        </li>
      </ul>
      <div id="hamburger" @click="toggleMenu">
        <span class="bar" v-for="i in 3" :key="i"></span>
      </div>
    </div>
  </header>
</template>

<script>
import LocaleDropdown from "@/components/LocaleDropdown";
import LinkBtn from '@/components/LinkBtn';
import { TweenMax, Expo } from 'gsap'
export default {
  name: "Menu",
  components: {
    LocaleDropdown,
    LinkBtn
  },
  data() {
    return {
      isOpen: 0,
      menuItems: [
        {
          name: this.$t('menu_item_1'),
          route: 'tools'
        },
        {
          name: this.$t('menu_item_2'),
          route: 'fees'
        },
        {
          name: this.$t('menu_item_3'),
          route: 'account-types'
        },
        {
          name: this.$t('menu_item_5'),
          route: 'about-us'
        },
        {
          name: this.$t('menu_item_4'),
          route: 'support'
        }
      ]
    }
  },
  computed: {
    currentRoute() {
      return this.$route.name;
    },
  },
  methods: {
    toggleMenu() {
      const vw = window.innerWidth
      let delay_time = 0
      this.isOpen = !this.isOpen
      document.getElementById('hamburger').classList.toggle('open')
      if (this.isOpen) {
        TweenMax.to('#bg-menu-mobile', 1, {
          x: -vw,
          ease: Expo.easeInOut
        })
        this.$el.querySelectorAll('li').forEach(li => {
          TweenMax.to(li, 1.2, {
            x: -vw,
            scaleX: 1,
            delay: delay_time,
            ease: Expo.easeInOut
          })
          delay_time += 0.04
        })
      } else {
        TweenMax.to('#bg-menu-mobile', 1.2, {
          x: 0,
          ease: Expo.easeInOut
        })
        this.$el.querySelectorAll('li').forEach(li => {
          TweenMax.to(li, 1, {
            x: 0,
            delay: delay_time,
            ease: Expo.easeInOut
          })
          delay_time += 0.02
        })
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.mobile-login__btn {
  a {
    background-color: #fff;
  }
}
#menu-mobile {
  #bg-menu-mobile {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    right: -100%;
    background-color: #384160;
    z-index: 9;
    will-change: transform;
  }

  ul {
    width: 100%;
    position: fixed;
    top: 100px;
    left:0;
    list-style: none;
    color: #fff;
    z-index: 9;
    li {
      font-weight:300;
      font-size: 30px;
      position: relative;
      text-align: center;
      margin-bottom: 15px;
      left:100%;
      cursor: pointer;
      will-change: transform;
      a {
        font-size: 20px;
        color: #F7F2EC;
        text-decoration: none;
      }
      &:hover {
        color: #111;
        -webkit-transition: all .1s ease-in-out;
        -moz-transition: all .1s ease-in-out;
        transition: all .1s ease-in-out;
      }
    }
  }
}

img {
  max-width: 100px;
}

#hamburger {
  width: 37px;
  height: 33px;
  margin: 40px auto;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 60px;
  background-color: transparent;
  z-index: 9;
}

#hamburger:hover span:nth-child(1) {
  -webkit-transition: .20s ease-in-out;
  -moz-transition: .20s ease-in-out;
  -o-transition: .20s ease-in-out;
  transition: .20s ease-in-out;
}

#hamburger:hover span:nth-child(3) {
  -webkit-transition: .16s ease-in-out;
  -moz-transition: .16s ease-in-out;
  -o-transition: .16s ease-in-out;
  transition: .16s ease-in-out;
}

#hamburger span {
  z-index: 3;
  display: block;
  position: absolute;
  height: 4px;
  width: 100%;
  background: #111;
  border-radius: 5px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .20s ease-in-out;
  -moz-transition: .20s ease-in-out;
  -o-transition: .20s ease-in-out;
  transition: .25s ease-in-out;
}

#hamburger span:nth-child(1) {
  top: 0;
}

#hamburger span:nth-child(2) {
  top: 12px;
}

#hamburger span:nth-child(3) {
  top: 24px;
}

#hamburger.open span:nth-child(1) {
  top: 14px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
  background-color: #fff;
}

#hamburger.open span:nth-child(2) {
  opacity: 0;
  left: -30px;
  -webkit-transition: .16s ease-in-out;
  -moz-transition: .16s ease-in-out;
  -o-transition: .16s ease-in-out;
  transition: .16s ease-in-out;
}

#hamburger.open span:nth-child(3) {
  top: 14px;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
  background-color: #fff;
}

.header__banner {
  background-color: #F7F2EC;
  height: 100px;
  border-bottom: 2px solid #959595;
  .navbar__banner {
    width: 100%;
    max-width: 1220px;
    margin: 0 auto;
    list-style: none;
    padding: 0;
    .nav__item {
      .nav__link {
        font-family: "OpenSans-Light", sans-serif;
        position: relative;
        color: #464646;
        text-decoration: none;
        &.active {
          &:before {
            opacity: 1;
            transform: translateY(0px);
          }
          &:after {
            opacity: 1;
            transform: translateY(0px);
          }
        }
        &:hover {
           &:before, &:after {
             opacity: 1;
             transform: translateY(0px);
           }
         }
        &:before, &:after {
          position: absolute;
          left: 0px;
          width: 100%;
          height: 2px;
          background: #384160;
          content: "";
          opacity: 0;
          transition: all 0.3s;
        }
        &:before {
          top: 0;
          transform: translateY(10px);
        }
        &:after {
          bottom: 0;
          transform: translateY(-10px);
        }
      }
    }
    .link-other__btn {
      padding: 10px 19px;
      background-color: #384160;
      color: #fff;
      text-decoration: none;
    }
  }
}
.dropdown-toggle {
  border: 0;
  background-color: transparent;
}
</style>

