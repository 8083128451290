<template>
  <div class="main-banner w-100 d-flex flex-column flex-xl-row justify-content-start justify-content-xl-center align-items-center">
    <img class="img-fluid el-1 d-none d-xl-flex" src="@/assets/img/index/banner/main.png" alt="Main banner">
    <img class="img-fluid el-2 d-none d-xl-flex" src="@/assets/img/index/banner/coin-3.png" alt="Coin icon">
    <img class="img-fluid el-3 d-none d-xl-flex" src="@/assets/img/index/banner/coin-2.png" alt="Coin icon">
    <img class="img-fluid el-4 d-none d-sm-flex" src="@/assets/img/index/banner/coin-4.png" alt="Coin icon">
    <img class="img-fluid el-5 d-none d-xl-flex" src="@/assets/img/index/banner/money.png" alt="Money icon">
    <div class="text__block d-flex align-items-start flex-column">
      <h1 class="title el-7" v-html="$t('main_banner_title')"></h1>
      <LinkBtn class-name="el-9 mt-4 mt-xl-0" />
    </div>
    <div class="d-none d-sm-flex d-xl-none justify-content-center align-items-baseline">
      <img class="img-fluid mt-5" src="@/assets/img/index/banner/main-tablet.png" alt="Main banner">
    </div>
     <div class="d-flex d-sm-none justify-content-center align-items-baseline">
       <img class="img-fluid mt-5" src="@/assets/img/index/banner/main-mob.png" alt="Main banner">
     </div>
  </div>
</template>

<script>
import LinkBtn from '@/components/LinkBtn';
import { gsap } from 'gsap';
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
export default {
  name: 'Banner',
  components: { LinkBtn },
  mounted() {
    if (window.innerWidth >= 1200) {
      var readyStateCheckInterval = setInterval(function () {
        if (document.readyState === "complete") {
          clearInterval(readyStateCheckInterval)
          gsap.timeline().to(".el-1", {
            x: 100,
            scrollTrigger: {
              start: "top center",
              end: "top 20px",
              scrub: 10,
              id: "scrub"
            }
          })
          gsap.timeline().to(".el-2", {
            y: 75,
            rotate: -10,
            scrollTrigger: {
              start: "top center",
              end: "top 20px",
              scrub: 10,
              id: "scrub"
            }
          })
          gsap.timeline().to(".el-3", {
            y: 75,
            rotate: -145,
            scrollTrigger: {
              start: "top center",
              end: "top 20px",
              scrub: 10,
              id: "scrub"
            }
          })
          gsap.timeline().to(".el-4", {
            rotate: 45,
            scale: 1.2,
            scrollTrigger: {
              start: "top center",
              end: "top 20px",
              scrub: 10,
              id: "scrub"
            }
          })
          gsap.timeline().to(".el-5", {
            scale: 1,
            scrollTrigger: {
              start: "top center",
              end: "top 20px",
              scrub: 10,
              id: "scrub"
            }
          })
        }
      }, 500)
    }
  },
}

</script>

<style lang="scss" scoped>
.main-banner {
  position: relative;
  max-width: 1220px;
  min-height: 770px;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  margin-top: 50px;
  &:before, &:after {
    content: '';
    position: absolute;
    width: 200px;
    height: 200px;
    background-repeat: no-repeat;
  }
  &:before {
    background-image: url("@/assets/img/index/banner/triangle-1.png");
    top: 0;
    left: 0;
  }
  &:after {
    background-image: url("@/assets/img/index/banner/triangle-2.png");
    bottom: 0;
    right: 0;
    width: 200px;
    height: 264px;
  }
  .el-1 {
    position: absolute;
    bottom: 7%;
    left: -50px;
  }
  .el-2 {
    transform: rotate(90deg);
    position: absolute;
    bottom: 55%;
    left: 5%;
  }
  .el-3 {
    position: absolute;
    top: 0;
    right: 20%;
  }
  .el-4 {
    transform: rotateY(45deg);
    position: absolute;
    bottom: 15%;
    right: 25%;
  }
  .el-5 {
    position: absolute;
    bottom: 0;
    right: 30%;
    transform: scale(.8);
  }
  .text__block {
    position: absolute;
    top: 16%;
    right: 0;
    .btn {
      position: absolute;
      right: 25%;
    }
  }
}
</style>
