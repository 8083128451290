<template>
  <section>
    <Menu />
    <main>
      <Banner />
      <Why />
      <TradingInstruments />
    </main>
      <TransparentFees />

    <main class="overflow-hidden">
      <AccountTypes />
      <Support />
      <NexusPro />
    </main>
    <Footer />
  </section>
</template>

<script>
import Menu from '@/components/Menu';
import Banner from '@/components/index/Banner';
import Why from '@/components/index/Why';
import TradingInstruments from '@/components/index/TradingInstruments';
import TransparentFees from '@/components/index/TransparentFees';
import AccountTypes from '@/components/index/AccountTypes';
import Support from '@/components/index/Support';
import NexusPro from '@/components/index/NexusPro';
import Footer from '@/components/Footer';

export default {
  name: 'HomeView',
  components: {
    Menu,
    Banner,
    Why,
    TradingInstruments,
    TransparentFees,
    AccountTypes,
    Support,
    NexusPro,
    Footer
  },
  layout: 'basic',

  metaInfo () {
    return { title: this.$t('meta.title_main_page'), meta: [{
        vmid: 'description',
        name: 'description',
        content: this.$t('meta.desc_main_page')
      }],
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
