<template>
  <!-- <div :style="{
    width: `${percent}%`,
    height: height,
    opacity: show ? 1 : 0,
    'background-color': canSuccess ? color : failedColor
  }" class="progress"
  /> -->
  <div class="page-preloader" :style="{ display: show ? 'block' : 'none' }">
    <!-- :class="{'hide': !show}" -->
    <!-- <div class="big-rect"></div>
    <div class="small-rect"></div> -->
    <div id="preloader">
      <div id="loader"></div>
    </div>
  </div>
</template>

<script>
// https://github.com/nuxt/nuxt.js/blob/master/lib/app/components/nuxt-loading.vue
export default {
  name: "LoadingComponent",
  data: () => ({
    percent: 0,
    show: false,
    canSuccess: true,
    duration: 3000,
    height: "2px",
    color: "#77b6ff",
    failedColor: "red",
  }),
  methods: {
    start() {
      this.show = true;
      this.canSuccess = true;
      if (this._timer) {
        clearInterval(this._timer);
        this.percent = 0;
      }
      this._cut = 10000 / Math.floor(this.duration);
      this._timer = setInterval(() => {
        this.increase(this._cut * Math.random());
        if (this.percent > 95) {
          this.finish();
        }
      }, 100);
      return this;
    },
    set(num) {
      this.show = true;
      this.canSuccess = true;
      this.percent = Math.floor(num);
      return this;
    },
    get() {
      return Math.floor(this.percent);
    },
    increase(num) {
      this.percent = this.percent + Math.floor(num);
      return this;
    },
    decrease(num) {
      this.percent = this.percent - Math.floor(num);
      return this;
    },
    finish() {
      this.percent = 100;
      this.hide();
      return this;
    },
    pause() {
      clearInterval(this._timer);
      return this;
    },
    hide() {
      clearInterval(this._timer);
      this._timer = null;
      setTimeout(() => {
        this.show = false;
        this.$nextTick(() => {
          setTimeout(() => {
            this.percent = 0;
          }, 200);
        });
      }, 1000);
      return this;
    },
    fail() {
      this.canSuccess = false;
      return this;
    },
  },
};
</script>

<style lang="scss" scoped>
.page-preloader {
  position: fixed;
  z-index: 999999;
  background-color: #dfdfdf;
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: block;
  // opacity: 1;
  pointer-events: none;
  @media (min-width: $md3+px) {
    pointer-events: visible;
  }
  &.hide {
    display: none;
    // pointer-events: none;
    // transition: opacity .1s;
    // opacity: 0;
    // .big-rect {
    //   animation-play-state: .1s paused;
    // }
    // .small-rect {
    //   animation-play-state: .1s paused;
    // }
  }
  .big-rect {
    border: 8px solid #f9d36d;
    border-radius: 40px;
    width: 220px;
    height: 220px;
    position: absolute;
    left: calc(50% - 110px);
    top: calc(50% - 110px);
    animation: 1s linear infinite rotate-big;
    animation-play-state: running;
    @media (max-width: $md1+px) {
      width: 180px;
      height: 180px;
      left: calc(50% - 90px);
      top: calc(50% - 90px);
    }
    @media (max-width: $md6+px) {
      width: 120px;
      height: 120px;
      border-radius: 20px;
      left: calc(50% - 60px);
      top: calc(50% - 60px);
    }
    @keyframes rotate-big {
      0% {
        transform: rotate(45deg);
      }
      40% {
        transform: rotate(135deg);
      }
      60% {
        transform: rotate(135deg);
      }
      100% {
        transform: rotate(135deg);
      }
    }
  }
  .small-rect {
    border: 8px solid #f9d36d;
    border-radius: 20px;
    width: 140px;
    height: 140px;
    position: absolute;
    left: calc(50% - 70px);
    top: calc(50% - 70px);
    animation: 1s linear infinite rotate-small;
    animation-play-state: running;
    @media (max-width: $md1+px) {
      width: 120px;
      height: 120px;
      left: calc(50% - 60px);
      top: calc(50% - 60px);
    }
    @media (max-width: $md6+px) {
      width: 70px;
      height: 70px;
      left: calc(50% - 35px);
      top: calc(50% - 35px);
      border-radius: 10px;
    }
    @keyframes rotate-small {
      0% {
        transform: rotate(45deg);
      }
      40% {
        transform: rotate(-45deg);
      }
      60% {
        transform: rotate(-45deg);
      }
      100% {
        transform: rotate(-45deg);
      }
    }
  }
}
.progress {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 2px;
  width: 0%;
  transition: width 0.2s, opacity 0.4s;
  opacity: 1;
  background-color: #efc14e;
  z-index: 999999;
}
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #F7F2EC;
  height: 100%;
}
#loader {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #384160;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}
#loader:before {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #384160;
  -webkit-animation: spin 3s linear infinite;
  animation: spin 3s linear infinite;
}
#loader:after {
  content: "";
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #384160;
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
