<template>
  <div class="container nexus-pro_section">
    <h4 class="title text-center" v-html="$t('nexus_pro_section.title')"></h4>
    <div class="step__wrap d-flex justify-content-between flex-wrap align-items-center">
      <div v-for="(item, key) in steps" :key="key" class="step__item d-flex flex-column align-items-center justify-content-center col-12 col-md-4">
        <img :src="item.image" :alt="item.title">
        <h6>{{item.title}}</h6>
        <p>{{item.text}}</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'NexusPro',
  data() {
    return {
      steps: [
        {
          image: require('@/assets/img/index/step-1.png'),
          title: this.$t('nexus_pro_section.items.title_0'),
          text: this.$t('nexus_pro_section.items.text_0')
        },
        {
          image: require('@/assets/img/index/step-2.png'),
          title: this.$t('nexus_pro_section.items.title_1'),
          text: this.$t('nexus_pro_section.items.text_1')
        },
        {
          image: require('@/assets/img/index/step-3.png'),
          title: this.$t('nexus_pro_section.items.title_2'),
          text: this.$t('nexus_pro_section.items.text_2')
        },
      ]
    }
  }
}
</script>
<style lang="scss">
.nexus-pro_section {
  padding-top: 170px;
  .step__wrap {
    padding-top: 70px;
    .step__item {
      img {
        padding-bottom: 40px;
      }
      h6 {
        font-weight: 800;
        font-size: 20px;
        color: #464646;
      }
      p {
        font-family: "OpenSans-Light", sans-serif;
        max-width: 250px;
        width: 100%;
        text-align: center;
      }
    }
  }
}
</style>
