<template>
  <div class="container content__wrap rewards-trading__container">
    <div class="d-flex justify-content-start justify-content-xl-center">
      <h4 class="title">{{ $t('support_page.content.title') }}</h4>
    </div>
    <div class="row justify-content-xl-between">
      <div class="col-12 col-xl-5">
        <p>{{ $t('support_page.content.text_0') }}</p>
        <p>{{ $t('support_page.content.text_1') }}</p>
      </div>
      <div class="col-12 col-xl-5">
        <p>{{ $t('support_page.content.text_2') }}</p>
        <p>{{ $t('support_page.content.text_3') }}</p>
      </div>
      <div class="row gap-t-170">
        <div class="col-12 col-xl-4 mb-5 mb-xl-0">
          <div class="item__wrap d-flex justify-content-center align-items-center flex-column">
            <img src="@/assets/img/support/img-1.png" alt="" class="img-fluid">
            <h5 class="mt-5 mb-4">{{ $t('support_page.content.text_4') }}</h5>
            <p class="text-center">{{ $t('support_page.content.text_5') }}</p>
          </div>
        </div>
        <div class="col-12 col-xl-4">
          <div class="item__wrap d-flex justify-content-center align-items-center flex-column">
            <img src="@/assets/img/support/img-2.png" alt="" class="img-fluid">
            <h5 class="mt-5 mb-4">{{ $t('support_page.content.text_6') }}</h5>
            <p class="text-center">{{ $t('support_page.content.text_7') }}</p>
          </div>
        </div>
        <div class="col-12 col-xl-4">
          <div class="item__wrap d-flex justify-content-center align-items-center flex-column">
            <img src="@/assets/img/support/img-3.png" alt="" class="img-fluid">
            <h5 class="mt-5 mb-4">{{ $t('support_page.content.text_8') }}</h5>
            <p class="text-center">{{ $t('support_page.content.text_9') }}</p>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-start justify-content-xl-center gap-t-170">
        <h4 class="title">{{ $t('support_page.content.text_10') }}</h4>
      </div>
      <div class="col-12 col-xl-5">
        <div class="faq__container">
          <div class="accordion" id="accordionSupport">
            <div v-for="(item, key) in accordingItems" :key="key" class="accordion-item">
              <h2 class="accordion-header">
                <button class="accordion-button" :class="{collapsed: item.id !== 1}" type="button" data-bs-toggle="collapse" :data-bs-target="`#collapseOne_${key}`" aria-expanded="true" aria-controls="collapseOne">
                  {{ item.title }}
                </button>
              </h2>
              <div :id="`collapseOne_${key}`" class="accordion-collapse collapse" :class="{show: item.id === 1}" data-bs-parent="#accordionSupport">
                <div class="accordion-body">
                  <p>
                    {{ item.text }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-xl-5">
        <p>{{ $t('support_page.content.text_11') }}</p>
        <p>{{ $t('support_page.content.text_12') }}</p>
        <LinkBtn class-name="mx-0 mt-4 mt-xl-5" />
      </div>
      <div class="gap-t-170">
        <h4 class="title text-center">
          {{ $t('support_page.content.text_13') }}
        </h4>
        <p class="desc text-center">{{ $t('support_page.content.text_14') }}</p>
        <div class="accordion__container">
          <div class="accordion" id="accordionSupport">
            <div v-for="(item, key) in supportItems" :key="key" class="accordion-item">
              <h2 class="accordion-header">
                <button class="accordion-button" :class="{collapsed: item.id !== 1}" type="button" data-bs-toggle="collapse" :data-bs-target="`#collapseOne_${key}`" aria-expanded="true" aria-controls="collapseOne">
                  <span>0{{item.id}}</span> {{ item.title }}
                </button>
              </h2>
              <div :id="`collapseOne_${key}`" class="accordion-collapse collapse" :class="{show: item.id === 1}" data-bs-parent="#accordionSupport">
                <div class="accordion-body">
                  <p>
                    {{ item.text }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="gap-t-170">
        <h4 class="title text-center">
          {{ $t('support_page.content.text_15') }}
        </h4>
        <p class="desc text-center">{{ $t('support_page.content.text_16') }}</p>
        <div class="accordion__container">
          <div class="accordion" id="accordionKnowledge">
            <div v-for="(item, key) in multilingualSupport" :key="key" class="accordion-item">
              <h2 class="accordion-header">
                <button class="accordion-button" :class="{collapsed: item.id !== 1}" type="button" data-bs-toggle="collapse" :data-bs-target="`#collapseOne_${key}`" aria-expanded="true" aria-controls="collapseOne">
                  <span>0{{item.id}}</span> {{ item.title }}
                </button>
              </h2>
              <div :id="`collapseOne_${key}`" class="accordion-collapse collapse" :class="{show: item.id === 1}" data-bs-parent="#accordionKnowledge">
                <div class="accordion-body">
                  <p>
                    {{ item.text }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LinkBtn from '@/components/LinkBtn';
export default {
  name: 'Content',
  components: {
    LinkBtn
  },
  data() {
    return {
      accordingItems: [
        {
          id: 1,
          title: this.$t('support_page.content.items_0.title_0'),
          text: this.$t('support_page.content.items_0.text_0')
        },
        {
          id: 2,
          title: this.$t('support_page.content.items_0.title_1'),
          text: this.$t('support_page.content.items_0.text_1')
        },
        {
          id: 3,
          title: this.$t('support_page.content.items_0.title_2'),
          text: this.$t('support_page.content.items_0.text_2')
        },
      ],
      supportItems: [
        {
          id: 1,
          title: this.$t('support_page.content.items_1.title_1'),
          text: this.$t('support_page.content.items_1.text_1')
        },
        {
          id: 2,
          title: this.$t('support_page.content.items_1.title_2'),
          text: this.$t('support_page.content.items_1.text_2')
        },
        {
          id: 3,
          title: this.$t('support_page.content.items_1.title_2'),
          text: this.$t('support_page.content.items_1.text_2')
        },
      ],
      multilingualSupport: [
        {
          id: 1,
          title: this.$t('support_page.content.items_2.title_0'),
          text: this.$t('support_page.content.items_2.text_0')
        },
        {
          id: 2,
          title: this.$t('support_page.content.items_2.title_1'),
          text: this.$t('support_page.content.items_2.text_1')
        },
        {
          id: 3,
          title: this.$t('support_page.content.items_2.title_2'),
          text: this.$t('support_page.content.items_2.text_2')
        },
      ]
    }
  }
}
</script>
<style lang="scss" scoped>
.content__wrap {
  padding-top: 90px;
  .gap-t-170 {
    padding-top: 170px;
    .desc {
      margin: 0 auto;
      max-width: 660px;
      width: 100%;
    }
    .title {
      padding-bottom: 30px;
    }
  }
  .title {
    padding-bottom: 75px;
  }
  .col-xl-5 {
    p {
      font-family: "OpenSans-Light", sans-serif;
    }
  }
  .faq__container {
    padding-top: 0;
    .accordion-button {
      font-size: 20px;
      color: #384160;
      padding: 0;
      border: none;
      border-radius: 0!important;
      background-color: transparent;
      box-shadow: none;
    }
    .accordion-item {
      position: relative;
      padding: 3px 45px 3px 45px;
      background-color: transparent;
      border: #959595;
      margin-bottom: 40px;
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 8px;
        background-color: #384160;
      }
    }
    .accordion-body {
      padding: 15px 0;
      p {
        font-family: "OpenSans-Light", sans-serif;
      }
    }
    .accordion-button::after {
      display: none;
    }
  }
}
</style>
