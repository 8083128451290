<template>
  <div class="row">
    <div class="col-12 col-sm-6 col-md-7">
      <div
          v-for="tab in tabs"
          :key="tab.name"
          class="tab"
          :class="{ active: tab.isActive }"
          @click="selectTab(tab)"
      >
        <div class="wrap">
          <div class="line-left"></div>
        </div>
        <span>{{ tab.name }}</span>
      </div>
    </div>
    <div class="col-12 col-sm-6 col-md-5">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      tabs: []
    }
  },
  created () {
    this.tabs = this.$children
  },
  methods: {
    selectTab (selectedTab) {
      this.tabs.forEach(tab => {
        tab.isActive = (tab.name === selectedTab.name)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.tab {
  padding: 10px 13px 10px 13px;
  margin: 0 10px;
  transition: all 0.3s ease;
  cursor: pointer;
  position: relative;
}

.tab.active {
  color: #464646;
  font-weight: bold;
  transform: translateX(65px);
  transition: transform .5s ease;
  .wrap, .line-left {
    width: 45px;
    transition: width .5s ease;
  }
}
.wrap {
  position: absolute;
  top: 50%;
  left: -27px;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.line-left {
  background: #464646;
  height: 1px;
  margin-bottom: 0;
  width: 0;
  transition: width .5s ease;
}
</style>
