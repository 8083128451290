<template>
  <div class="explore-trading-tools__container container">
    <h4 class="title text-center">
      {{ $t('trading_tools_page.explore_trading_tools.title') }}
    </h4>
    <p class="desc text-center">{{ $t('trading_tools_page.explore_trading_tools.desc') }}</p>
    <div class="accordion__container">
      <div class="accordion" id="accordionExplore">
        <div v-for="(item, key) in accordingItems" :key="key" class="accordion-item">
          <h2 class="accordion-header">
            <button class="accordion-button" :class="{collapsed: item.id !== 1}" type="button" data-bs-toggle="collapse" :data-bs-target="`#collapseOne_${key}`" aria-expanded="true" aria-controls="collapseOne">
              <span>0{{item.id}}</span> {{ item.title }}
            </button>
          </h2>
          <div :id="`collapseOne_${key}`" class="accordion-collapse collapse" :class="{show: item.id === 1}" data-bs-parent="#accordionExplore">
            <div class="accordion-body">
              <p>
                {{ item.text }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row gap-t-190">
      <div class="col-12 col-md-7 d-none d-xl-block">
        <div class="d-flex justify-content-start align-items-baseline w-100">
          <img src="@/assets/img/tools/explore-img.png" alt="" class="img-fluid">
        </div>
      </div>
      <div class="col-12 col-xl-5">
        <div class="d-flex flex-column justify-content-center h-100">
          <p id="explore-el-1">
            {{ $t('trading_tools_page.explore_trading_tools.text_0') }}
          </p>
          <p id="explore-el-2">
            {{ $t('trading_tools_page.explore_trading_tools.text_1') }}
          </p>
          <LinkBtn class-name="mx-0 mt-4" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LinkBtn from '@/components/LinkBtn.vue';
import { gsap } from 'gsap';

export default {
  name: 'ExploreTradingTools',
  components: { LinkBtn },
  data() {
    return {
      accordingItems: [
        {
          id: 1,
          title: this.$t('trading_tools_page.explore_trading_tools.items.title_0'),
          text: this.$t('trading_tools_page.explore_trading_tools.items.text_0')
        },
        {
          id: 2,
          title: this.$t('trading_tools_page.explore_trading_tools.items.title_1'),
          text: this.$t('trading_tools_page.explore_trading_tools.items.text_1')
        },
        {
          id: 3,
          title: this.$t('trading_tools_page.explore_trading_tools.items.title_2'),
          text: this.$t('trading_tools_page.explore_trading_tools.items.text_2')
        },
        {
          id: 4,
          title: this.$t('trading_tools_page.explore_trading_tools.items.title_3'),
          text: this.$t('trading_tools_page.explore_trading_tools.items.text_3')
        },
        {
          id: 5,
          title: this.$t('trading_tools_page.explore_trading_tools.items.title_4'),
          text: this.$t('trading_tools_page.explore_trading_tools.items.text_4')
        },
        {
          id: 6,
          title: this.$t('trading_tools_page.explore_trading_tools.items.title_5'),
          text: this.$t('trading_tools_page.explore_trading_tools.items.text_5')
        },
      ]
    }
  },
  mounted() {
    if (window.innerWidth >= 1200) {
      const elements = [
        { id: '#explore-el-1', x: 200 },
        { id: '#explore-el-2', x: 200 },
      ];
      elements.forEach(element => {
        gsap.fromTo(
            document.querySelector(element.id),
            {
              opacity: 0,
              x: element.x,
            },
            {
              opacity: 1,
              x: 0,
              duration: 3,
              scrollTrigger: {
                trigger: document.querySelector(element.id),
                start: "top bottom",
              }
            }
        );
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.gap-t-190 {
  padding-top: 190px;
}
.explore-trading-tools__container {
  padding-top: 170px;

  .title {
    margin-bottom: 40px;
  }
  .desc {
    margin: 0 auto;
    max-width: 660px;
    width: 100%;
  }
  .accordion__container {
    padding-top: 75px;
    .accordion-button {
      font-family: "OpenSans-Bold", sans-serif;
      font-size: 25px;
      color: #384160;
      padding: 0;
      border: none;
      border-radius: 0!important;
      background-color: transparent;
      box-shadow: none;
      span {
        font-size: 16px;
        padding-right: 31px;
      }
    }
    .accordion-item {
      padding: 23px 45px 23px 45px;
      background-color: rgba(217, 217, 217, 0.2);
      border: #959595;
    }
    .accordion-body {
      p {
        font-family: "OpenSans-Light", sans-serif;
        padding-left: 31px;
      }
    }
  }
  .accordion-button::after {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjIiIGhlaWdodD0iMjIiIHZpZXdCb3g9IjAgMCAyMiAyMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTExIDFWMjFNMjEgMTFIMSIgc3Ryb2tlPSJibGFjayIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K");
    transition: all 0.5s;
    position: relative;
    bottom: 1px;
  }
  .accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjIiIGhlaWdodD0iMiIgdmlld0JveD0iMCAwIDIyIDIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0yMSAxSDEiIHN0cm9rZT0iYmxhY2siIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==");
    position: relative;
    bottom: 10px;
  }
  .accordion-button::after {
    transition: all 0.5s;
  }

}
</style>
