<template>
  <div class="container trading-fees__container">
    <div class="tabs__wrap">
      <Tabs col-class="d-flex justify-content-center" main-class="tabs flex-wrap justify-content-start justify-content-xl-center">
        <template v-for="(tab, key) in tabs">
          <Tab :name="tab.title" :selected="key === 0 ?? true" :key="key">
            <div class="tab__item d-flex flex-column flex-wrap justify-content-center align-items-center">
              <h1 class="title">{{ tab.title }}</h1>
              <p class="desc mb-4">{{ tab.desc }}</p>
              <div class="accordion__container w-100">
                <div class="accordion">
                  <div v-for="(item, key) in tab.items" :key="key" class="accordion-item">
                    <h2 class="accordion-header">
                      <button class="accordion-button" :class="{collapsed: item.id !== 1}" type="button" data-bs-toggle="collapse" :data-bs-target="`#${item.itemId}_${key}`" aria-expanded="true" :aria-controls="item.itemId">
                        <span>0{{item.id}}</span> {{ item.title }}
                      </button>
                    </h2>
                    <div :id="`${item.itemId}_${key}`" class="accordion-collapse collapse" :class="{show: item.id === 1}" :data-bs-parent="`#${item.itemId}`">
                      <div class="accordion-body">
                        <p>
                          {{ item.text }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Tab>
        </template>
      </Tabs>
      <div class="row gap-t-165">
        <div class="col-12 col-xl-5">
          <div class="d-flex flex-column justify-content-between h-100">
            <p id="fees-el-1">{{ $t('fees_page.trading_fees.text_0') }}</p>
            <p id="fees-el-2">{{ $t('fees_page.trading_fees.text_1') }}</p>
            <LinkBtn class-name="mx-0 mt-30" />
          </div>
        </div>
        <div class="col-12 col-md-7 d-none d-xl-block">
          <div class="d-flex justify-content-end align-items-baseline w-100">
            <img src="@/assets/img/fees/img-1.png" alt="" class="img-fluid">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Tabs from '@/components/Global/Tabs'
import Tab from '@/components/Global/Tab'
import LinkBtn from '@/components/LinkBtn';
import { gsap } from 'gsap';

export default {
  name: 'TradingFees',
  components: {
    LinkBtn,
    Tabs,
    Tab
  },
  data () {
    return {
      tabs: [
        {
          title: this.$t('fees_page.trading_fees.tabs.title_0'),
          desc: this.$t('fees_page.trading_fees.tabs.text_0'),
          itemId: 'tradingFees',
          items: [
            {
              id: 1,
              title: this.$t('fees_page.trading_fees.tabs.items_0.title_0'),
              text: this.$t('fees_page.trading_fees.tabs.items_0.text_0')
            },
            {
              id: 2,
              title: this.$t('fees_page.trading_fees.tabs.items_0.title_1'),
              text: this.$t('fees_page.trading_fees.tabs.items_0.text_1')
            },
            {
              id: 3,
              title: this.$t('fees_page.trading_fees.tabs.items_0.title_2'),
              text: this.$t('fees_page.trading_fees.tabs.items_0.text_2')
            },
            {
              id: 4,
              title: this.$t('fees_page.trading_fees.tabs.items_0.title_3'),
              text: this.$t('fees_page.trading_fees.tabs.items_0.text_3')
            },
          ]
        },
        {
          title: this.$t('fees_page.trading_fees.tabs.title_1'),
          desc: this.$t('fees_page.trading_fees.tabs.text_1'),
          itemId: 'depositWithdrawalFees',
          items: [
            {
              id: 1,
              title: this.$t('fees_page.trading_fees.tabs.items_0.title_0'),
              text: this.$t('fees_page.trading_fees.tabs.items_0.text_0')
            },
            {
              id: 2,
              title: this.$t('fees_page.trading_fees.tabs.items_0.title_1'),
              text: this.$t('fees_page.trading_fees.tabs.items_0.text_1')
            },
            {
              id: 3,
              title: this.$t('fees_page.trading_fees.tabs.items_0.title_2'),
              text: this.$t('fees_page.trading_fees.tabs.items_0.text_2')
            },
            {
              id: 4,
              title: this.$t('fees_page.trading_fees.tabs.items_0.title_3'),
              text: this.$t('fees_page.trading_fees.tabs.items_0.text_3')
            },
          ]
        },
        {
          title: this.$t('fees_page.trading_fees.tabs.title_2'),
          desc: this.$t('fees_page.trading_fees.tabs.text_2'),
          itemId: 'inactivityFees',
          items: [
            {
              id: 1,
              title: this.$t('fees_page.trading_fees.tabs.items_0.title_0'),
              text: this.$t('fees_page.trading_fees.tabs.items_0.text_0')
            },
            {
              id: 2,
              title: this.$t('fees_page.trading_fees.tabs.items_0.title_1'),
              text: this.$t('fees_page.trading_fees.tabs.items_0.text_1')
            },
            {
              id: 3,
              title: this.$t('fees_page.trading_fees.tabs.items_0.title_2'),
              text: this.$t('fees_page.trading_fees.tabs.items_0.text_2')
            },
            {
              id: 4,
              title: this.$t('fees_page.trading_fees.tabs.items_0.title_3'),
              text: this.$t('fees_page.trading_fees.tabs.items_0.text_3')
            },
            {
              id: 5,
              title: this.$t('fees_page.trading_fees.tabs.items_0.title_4'),
              text: this.$t('fees_page.trading_fees.tabs.items_0.text_4')
            },
          ]
        },
        {
          title: this.$t('fees_page.trading_fees.tabs.title_2'),
          desc: this.$t('fees_page.trading_fees.tabs.text_2'),
          itemId: 'overnightFinancingFees',
          items: [
            {
              id: 1,
              title: this.$t('fees_page.trading_fees.tabs.items_0.title_0'),
              text: this.$t('fees_page.trading_fees.tabs.items_0.text_0')
            },
            {
              id: 2,
              title: this.$t('fees_page.trading_fees.tabs.items_0.title_1'),
              text: this.$t('fees_page.trading_fees.tabs.items_0.text_1')
            },
            {
              id: 3,
              title: this.$t('fees_page.trading_fees.tabs.items_0.title_2'),
              text: this.$t('fees_page.trading_fees.tabs.items_0.text_2')
            },
            {
              id: 4,
              title: this.$t('fees_page.trading_fees.tabs.items_0.title_3'),
              text: this.$t('fees_page.trading_fees.tabs.items_0.text_3')
            },
            {
              id: 5,
              title: this.$t('fees_page.trading_fees.tabs.items_0.title_4'),
              text: this.$t('fees_page.trading_fees.tabs.items_0.text_4')
            },
          ]
        }
      ]
    }
  },
  mounted() {
    const elements = [
      { id: '#fees-el-1', x: -200 },
      { id: '#fees-el-2', x: -200 },
    ];
    elements.forEach(element => {
      gsap.fromTo(
          document.querySelector(element.id),
          {
            opacity: 0,
            x: element.x,
          },
          {
            opacity: 1,
            x: 0,
            duration: 3,
            scrollTrigger: {
              trigger: document.querySelector(element.id),
              start: "top bottom",
            }
          }
      );
    });
  },
}
</script>
<style lang="scss" scoped>
.gap-t-165 {
  padding-top: 165px;
}
.trading-fees__container {
  padding-top: 140px;
  .title {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    svg {
      position: absolute;
      top: 25px;
      left: 12%;
    }
  }
  .desc {
    margin: 0 auto;
    max-width: 660px;
    width: 100%;
    text-align: center;
  }
  .tabs__wrap {
    .tab__item {
      transition: all .3s ease-in-out;
      .border-left {
        padding: 45px 0 45px 105px;
        border-left: 1px solid rgba(0, 0, 0, .5);
      }
    }
  }
  .accordion__container {
    .accordion-item {
      background-color: transparent;
    }
  }
}
.mt-30 {
  margin-top: 30px;
}
</style>
