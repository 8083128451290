<template>
  <div class="container why__section" id="benefitTrading">
    <div class="row">
      <div class="col-12 col-xl-6" id="el-1">
        <h3 class="title text-end d-none d-xl-block" v-html="$t('why_section.title')"></h3>
        <h3 class="title text-center d-flex d-xl-none"><span>{{ $t('why_section.adaptiveTitle') }}</span></h3>
      </div>
      <div class="col-12 col-xl-6" id="el-2">
        <p class="about__text">{{ $t('why_section.desc') }}</p>
      </div>
      <div
          class="col-12 col-md-6 mt-3"
          v-for="(item, index) in items" :key="index"
          :class="[{'d-flex justify-content-end right__side': item.id % 2 === 0} ]"
          :id="getItemId(item.id)"
      >
        <div class="item__wrap">
          <h4 class="item__title">{{ item.id }}</h4>
          <p class="item__text"><strong>{{ item.name }}</strong> {{ item.desc }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { gsap } from 'gsap';
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
export default {
  name: 'Why',

  data() {
    return {
      items: [
        {
          id: 1,
          name: this.$t('why_section.items.title_0'),
          desc: this.$t('why_section.items.text_0')
        },
        {
          id: 2,
          name: this.$t('why_section.items.title_1'),
          desc: this.$t('why_section.items.text_1')
        },
        {
          id: 3,
          name: this.$t('why_section.items.title_2'),
          desc: this.$t('why_section.items.text_2')
        },
        {
          id: 4,
          name: this.$t('why_section.items.title_3'),
          desc: this.$t('why_section.items.text_3')
        },
      ]
    }
  },
  mounted() {
    if (window.innerWidth >= 1200) {
      const elements = [
        { id: '#el-1', x: -200 },
        { id: '#el-2', x: 200 },
        { id: '#item-el-1', x: -200 },
        { id: '#item-el-2', x: 200 },
        { id: '#item-el-3', x: -200 },
        { id: '#item-el-4', x: 200 },
      ];

      elements.forEach(element => {
        gsap.fromTo(
            document.querySelector(element.id),
            {
              opacity: 0,
              x: element.x,
            },
            {
              opacity: 1,
              x: 0,
              duration: 3,
              scrollTrigger: {
                trigger: document.querySelector(element.id),
                start: "top bottom",
              }
            }
        );
      });
    }
  },
  methods: {
    getItemId(id) {
      return id % 2 !== 0 ? 'item-el-' + id : 'item-el-' + id
    }
  }
}
</script>
<style lang="scss">
.why__section {
  .row {
    padding: 200px 0 0;
  }
  .item__wrap {
    max-width: 525px;
    width: 100%;
    .item__title {
      font-family: 'OpenSans-Bold', sans-serif;
      font-size: 58px;
      background: linear-gradient(90deg, #384160 0%, #8B6277 10%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  .col-12 {

    p {
      font-family: "OpenSans-Light", sans-serif;
      &.about__text {
        width: 74%;
        padding-left: 35px;
        position: relative;
        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          width: 2px;
          background-color: #384160;
        }
      }
    }
  }
}
</style>
