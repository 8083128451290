<template>
  <div class="main-banner w-100 d-flex flex-column flex-xl-row flex-wrap justify-content-start justify-content-xl-center align-items-start">
    <div class="col-12 col-xl-6">
      <div class="text__block d-flex align-items-start flex-column">
        <h1 class="title" v-html="$t('support_page.banner.title')"></h1>
        <p>
          {{ $t('support_page.banner.desc_0') }}
        </p>
        <p>
          {{ $t('support_page.banner.desc_0') }}
        </p>
        <LinkBtn class-name="tools__btn" />
      </div>
      <div class="d-flex d-sm-none justify-content-center align-items-baseline">
        <img class="img-fluid mt-5" src="@/assets/img/support/main-banner-mob.png" alt="">
      </div>
      <div class="d-none d-sm-flex d-xl-none justify-content-center align-items-baseline">
        <img class="img-fluid mt-5" src="@/assets/img/support/main-banner-tablet.png" alt="">
      </div>
    </div>
    <div class="col-12 col-xl-6 mt-4 mt-xl-5">
      <img class="img-fluid el-1 d-none d-xl-flex" src="@/assets/img/support/main-banner.png" alt="">
    </div>
  </div>
</template>

<script>
import LinkBtn from '@/components/LinkBtn';

export default {
  name: 'Banner',
  components: { LinkBtn },
}

</script>

<style lang="scss" scoped>
.main-banner {
  position: relative;
  max-width: 1220px;
  min-height: 770px;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  margin-top: 50px;
  &:before {
    content: '';
    position: absolute;
    width: 200px;
    height: 200px;
    background-repeat: no-repeat;
  }
  &:before {
    background-image: url("@/assets/img/index/banner/triangle-1.png");
    top: 0;
    left: 0;
  }
  .el-2 {
    position: absolute;
    top: 45%;
    left: 40%;
  }

  .el-3 {
    position: absolute;
    top: 15%;
    right: 0;
  }
  .text__block {
    position: relative;
    top: 70px;
    .title {
      position: relative;
      margin-bottom: 15px;
      line-height: 1.4;
      svg {
        right: -100px;
        top: 50px;
      }
    }
    h5 {
      font-family: "OpenSans-Bold", sans-serif;
      font-size: 30px;
      color: #384160;
    }
    svg {
      position: absolute;
      right: -50px;
      top: 80px;
    }
    p {
      margin-top: 30px;
    }
    .tools__btn {
      margin: 50px 0 0;
    }
  }
}
</style>
