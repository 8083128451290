<template>
  <div id="app">
    <loading ref="loading" />
    <transition name="page" mode="out-in">
      <component :is="layout" v-if="layout" />
    </transition>
  </div>
</template>

<script>
import Loading from "@/components/Loading";
import { gsap, TimelineMax } from "gsap";
import MotionPathPlugin from "gsap/MotionPathPlugin";
gsap.registerPlugin(MotionPathPlugin);

// Load layout components dynamically.
const requireContext = require.context("@/layouts", false, /.*\.vue$/);

const layouts = requireContext
  .keys()
  .map((file) => [file.replace(/(^.\/)|(\.vue$)/g, ""), requireContext(file)])
  .reduce((components, [name, component]) => {
    components[name] = component.default || component;
    return components;
  }, {});

export default {
  el: "#app",

  components: {
    Loading,
  },

  data: () => ({
    layout: null,
    defaultLayout: "default",
  }),

  mounted() {
    this.$loading = this.$refs.loading;
    var circle = document.querySelectorAll(".circle");

    var tl = new TimelineMax({ repeat: -1, yoyo: true });

    tl.staggerFromTo(
      circle,
      1,
      {
        autoAlpha: 0,
        scale: 0.1,
      },
      {
        autoAlpha: 1,
        scale: 1,
        motionPath: {
          type: "soft",
          path: [
            { x: -50, y: -50 }, // P1
            { x: -100, y: 0 }, // P2
            { x: -50, y: 50 }, // P3
            { x: 50, y: -50 }, // P4
            { x: 100, y: 0 }, // P5
            { x: 50, y: 50 }, // P6
            { x: 0, y: 0 }, // P7
          ],
        },
        stagger: 0.1,
      }
    );
  },

  methods: {
    /**
     * Set the application layout.
     *
     * @param {String} layout
     */
    setLayout(layout) {
      if (!layout || !layouts[layout]) {
        layout = this.defaultLayout;
      }

      this.layout = layouts[layout];
    },
  },
};
</script>
