<template>
  <div class="container support_section">
    <h4 class="title text-center">
      {{ $t('support_section.title') }}
    </h4>
    <p class="desc text-center">{{ $t('support_section.desc') }}</p>
    <div class="accordion__container">
      <div class="accordion" id="accordionSupport">
        <div v-for="(item, key) in accordingItems" :key="key" class="accordion-item">
          <h2 class="accordion-header">
            <button class="accordion-button" :class="{collapsed: item.id !== 1}" type="button" data-bs-toggle="collapse" :data-bs-target="`#collapseOne_${key}`" aria-expanded="true" aria-controls="collapseOne">
              <span>0{{item.id}}</span> {{ item.title }}
            </button>
          </h2>
          <div :id="`collapseOne_${key}`" class="accordion-collapse collapse" :class="{show: item.id === 1}" data-bs-parent="#accordionSupport">
            <div class="accordion-body">
              <p>
                {{ item.text }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Support',
  data() {
    return {
      accordingItems: [
        {
          id: 1,
          title: this.$t('support_section.items.title_0'),
          text: this.$t('support_section.items.text_0')
        },
        {
          id: 2,
          title: this.$t('support_section.items.title_1'),
          text: this.$t('support_section.items.text_1')
        },
        {
          id: 3,
          title: this.$t('support_section.items.title_2'),
          text: this.$t('support_section.items.text_2')
        },
        {
          id: 4,
          title: this.$t('support_section.items.title_3'),
          text: this.$t('support_section.items.text_3')
        },
        {
          id: 5,
          title: this.$t('support_section.items.title_4'),
          text: this.$t('support_section.items.text_4')
        },
      ]
    }
  }
}
</script>
<style>

.support_section {
  padding-top: 170px;
  .title {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    svg {
      position: absolute;
      top: 40px;
      left: 20%;
    }
  }
  .desc {
    margin: 0 auto;
    max-width: 660px;
    width: 100%;
  }
}
</style>
