<template>
  <div>
    <div :class="mainClass">
      <div
          v-for="tab in tabs"
          :key="tab.name"
          :class="[colClass, 'mb-2 mb-xl-4']"
          @click="selectTab(tab)"
      >
        <div
            class="tab"
            :class="{ active: tab.isActive }">
          {{ tab.name }}
        </div>
      </div>
    </div>
    <div class="tab-content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    mainClass: { default: 'tabs flex-wrap justify-content-center' },
    colClass: { default: 'col-6 col-lg-4 col-xl-2'},
  },
  data () {
    return {
      tabs: []
    }
  },
  created () {
    this.tabs = this.$children
  },
  methods: {
    selectTab (selectedTab) {
      this.tabs.forEach(tab => {
        tab.isActive = (tab.name === selectedTab.name)
      })
    }
  }
}
</script>

<style scoped>
.tabs {
  display: flex;
  margin-bottom: 20px;
}

.tab {
  font-size: 14px;
  padding: 10px 13px 10px 13px;
  border: 1px solid #384160;
  margin: 0 10px;

  transition: all 0.3s ease;
  cursor: pointer;
}

.tab:hover, .tab.active {
  background-color: #38416080;
  border: 1px solid #38416080;
  color: #fff;
}

/* Анимация появления для контента вкладки */
.tab-content {
  animation: fadeIn 0.3s ease-in-out forwards;
}

/* Ключевые кадры для анимации fadeIn */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

</style>
