import HomeView from '../views/HomeView'
import TradingTools from '../views/TradingTools';
import FeesView from '@/views/FeesView';
import AccountTypes from '@/views/AccountTypes';
import AboutUsView from '@/views/AboutUsView';
import SupportViews from '@/views/SupportViews';

export default [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/trading-tools',
    name: 'tools',
    component: TradingTools,
  },
  {
    path: '/fees',
    name: 'fees',
    component: FeesView,
  },
  {
    path: '/account-types',
    name: 'account-types',
    component: AccountTypes,
  },
  {
    path: '/about-us',
    name: 'about-us',
    component: AboutUsView,
  },
  {
    path: '/support',
    name: 'support',
    component: SupportViews,
  },
];
